import React from 'react';
import { Link } from "react-router-dom";
import '../Styles/Navbar.scss';
import logo from '../Images/logo.png';

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navclass: ""
        };
        
        this.toggleNavbar = this.toggleNavbar.bind(this);
    }

    toggleNavbar() {
        if(this.state.navclass === "") {
            this.setState({navclass: "active"});
        } else {
            this.setState({navclass: ""});
        }
    }

    render() {
        

        return (
            <nav className={this.state.navclass}>
                <div className="header">
                    <Link className="logo" to="/"><img alt="Peko logo" src={logo}></img></Link>
                    <div className="open" onClick={this.toggleNavbar}><div></div><div></div><div></div></div>
                </div>
                <div className="links">
                    <Link to="/features">Features</Link>
                    <Link to="/story">Story</Link>
                    <Link to="/socials">Socials</Link>
                    <Link to="/contact">Contact</Link>
                    <Link to="https://docs.pekoui.com">Docs</Link>
                </div>
            </nav>
        );
    }
}

export default Navbar;
