import { React, useEffect } from "react";
import "../Styles/Features.scss"
import background from "../Images/Background2.jpeg";
import application from "../Images/application.png";
import application2 from "../Images/application2.png";
import application3 from "../Images/application3.png";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

function Features() {
    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        if(hash === '') {
            window.scrollTo(0, 0);
        } else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 100);
        }
    }, [pathname, hash, key]);

    return (
        <>
            <Helmet>
                <title>Peko - Features</title>
                <meta property="og:title" content="Peko" />
                <meta property="og:description" content="Learn more about the many features which enable Peko to be a leading edge technology in the crossplatform development industry" />
                <meta name="description" content="Learn more about the many features which enable Peko to be a leading edge technology in the crossplatform development industry" />
                <meta property="og:url" content="https://pekoui.com/features" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div id="feature-entrance">
                <div id="background">
                    <img alt="page header background" src={background}></img>
                    <div id="overlay"></div>
                </div>
                <div id="content">
                    <span id="title">Powerful features for powerful development</span>
                    <img alt="shocases application made in peko with simple code" id="image" src={application}></img>
                </div>
            </div>
            <div id="top-row">
                <div className="feature-section">
                    <h1 className="title">Powerful Cross-Platform Capabilties</h1>
                    <div className="feature-details">
                        <span className="description">The langauge which empowers the Peko platform, Pekoscript, creates native apps for all majors platforms, including Windows, MacOS, Linux, IOS, and Android.</span>
                    </div>
                </div>
                <div className="feature-section">
                    <h1 className="title">Descriptive Error Handling</h1>
                    <div className="feature-details">
                        <span className="description">Pekoscript is a hyper-powerful language, with the ability to provide descriptive errors during both runtime and compile time, with errors providing the exact location they occured as well as a description.</span>
                    </div>
                </div>
                <div className="feature-section">
                    <h1 className="title">Extensive Language</h1>
                    <div className="feature-details">
                        <span className="description">Pekoscript offers many different features, allowing for multiple solutions to be developed. Additionally, the extensive libraries Peko provides out of the box enable developers to quickly develop applications with ease.</span>
                    </div>
                </div>
            </div>
            <div id="banner">
                <div id="cross"></div>
                <div className="part">
                    <div>
                        <h1>Create UIs with speed</h1>
                        <span>Peko renders the UI utilizing the native webview for each platform, allowing for native and responsive applications.</span>
                    </div>
                    <img alt="showcases advanced application made with peko" src={application2}></img>
                </div>
                <div className="part">
                    <div>
                        <h1>Create apps for anywhere</h1>
                        <span>Peko is able to create app bundles for any desired platform in one command, while bundling images, icons, and other resources into the application</span>
                        <ul>
                            <li><div className="parts"><span className="part1">Windows</span><div className="part2"><div className="arrow"></div>.exe</div></div></li>
                            <li><div className="parts"><span className="part1">MacOS</span><div className="part2"><div className="arrow"></div>.app</div></div></li>
                            <li><div className="parts"><span className="part1">Linux</span><div className="part2"><div className="arrow"></div>.AppImage</div></div></li>
                            <li><div className="parts"><span className="part1">IOS</span><div className="part2"><div className="arrow"></div>.ipa</div></div></li>
                            <li><div className="parts"><span className="part1">Android</span><div className="part2"><div className="arrow"></div>.apk</div></div></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="bottom-row">
                <div id="tools"></div>
                <h1 id="title">Security on multiple levels</h1>
                <div id="cards">
                    <div className="card">
                        <h1>Intellisense</h1>
                        <span>IDE extensions for Peko not only convert popular development environments into Peko IDEs, but also assist in catching compile time errors as you write code.</span>
                    </div>
                    <div className="card">
                        <h1>Optional Types</h1>
                        <span>Pekoscript has optional types and runtime errors built-in, allowing developers to catch nasty bugs so they don't go unnoticed in production.</span>
                    </div>
                    <div className="card">
                        <h1>Credential Protection</h1>
                        <span>If database credentials are input into an application binary through raw strings, hackers are able to potentially uncover these credentials by searching the application binary. To defend this, Pekoscript is able to disquise credentials over many data regions so they cannot be easily uncovered.</span>
                    </div>
                </div>
            </div>
            <div id="last-row">
                <img alt="showcases the power behind the Pekoscript language" src={application3}></img>
                <div id="description">
                    <h1 id="language">Super Language</h1>
                    <span>Pekoscript has a range of features which cater to both the simplistic and advanced developers. Features like generics, function overloading, and advanced module systems allow for developer to enhance their codebase and improve efficiency. Features like iterators, automatic type casting, and keyword arguments help simplify and beautify the development process in Peko.</span>
                </div>
            </div>
        </>
    )
}

export default Features;