import {React, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../Styles/Main.scss"
import designer from "../Images/Designer.jpeg";
import codeimage1 from "../Images/codeimage1.png";
import codeimage2 from "../Images/codeimage2.png";
import codeimage3 from "../Images/codeimage3.png";
import codeimage4 from "../Images/codeimage4.png";
import network from "../Images/network.png";
import headshot from "../Images/headshot.png";
import { Helmet } from "react-helmet";

function Main() {
    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        if(hash === '') {
            window.scrollTo(0, 0);
        } else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 100);
        }
    }, [pathname, hash, key]);
    
    const [rotation, setRotation] = useState(1);

    let rotate_forward = () => {
        setRotation(rotation+1);
    };

    let rotate_backwards = () => {
        setRotation(rotation-1);
    };

    useEffect(() => {
        document.getElementById("root-rotate").style.transform = `rotateY(calc(${rotation}rad * -2.09439510239))`;
    })
    
    return (
        <>
        <Helmet>
            <title>Peko</title>
            <meta property="og:title" content="Peko" />
            <meta property="og:description" content="Welcome to Peko, a powerful crossplatform application development framework, enabling developers to swiftly and easily create full stack applications for all major platforms" />
            <meta name="description" content="Welcome to Peko, a powerful crossplatform application development framework, enabling developers to swiftly and easily create full stack applications for all major platforms" />
            <meta property="og:url" content="https://pekoui.com/" />
            <meta property="og:type" content="website" />
        </Helmet>
        <div id="entrance">
            <div id="background">
                <img alt="header background" src={designer}></img>
                <div id="overlay"></div>
            </div>
            <div id="text">
                <span id="title">Take your apps to new heights with Peko</span>
                <span id="quick-description">Peko is a toolset that enables developers to efficiently design powerful applications in one codebase that can be run on all major platforms.</span>
            </div>
        </div>
        <div className="rotating-container">
            <div className="rotating-display" id="root-rotate">
                <div className="slide">
                    <div className="description">
                        <h1>Powerful Cross-Platform Compiler</h1>
                        <span>Pekoscript comes with a powerful cross-platform compiler which can create applications for Android, IOS, MacOS, Windows, and Linux, allowing for seamless deployment of your codebase.</span>
                        <Link to="/features#cross">Learn more</Link>
                    </div>
                    <img alt="showcases the peko cli and its power" src={codeimage2}></img>
                </div>
                <div className="slide">
                    <div className="description">
                        <h1>Extensive Language and Libraries</h1>
                        <span>Pekoscript offers advanced features like generics and function overloading, allowing for elegant and powerful codebases. Additionally, Pekoscript includes a plethora of libraries for various tasks allowing for efficient development.</span>
                        <Link to="/features#language">Learn more</Link>
                    </div>
                    <div className="images">
                        <img alt="showcases the simple code for a counter app in peko" src={codeimage1}></img>
                        <img alt="showcases the simple code to create data structures in peko" src={codeimage4}></img>
                    </div>
                </div>
                <div className="slide">
                    <div className="description">
                        <h1>Elegant Tooling and Support</h1>
                        <span>The Pekoscript compiler provides insightful error handling for both compile time and runtime errors, allowing for development with ease.</span>
                        <Link to="/features#tools">Learn more</Link>
                    </div>
                    <img alt="showcases the powerful error handling of the peko cli" src={codeimage3}></img>
                </div>
            </div>
            <div className="cycle-buttons">
                <button className="backwards" onClick={rotate_forward}><div></div><div></div></button>
                <button className="forwards" onClick={rotate_backwards}><div></div><div></div></button>
            </div>
        </div>
        <br></br>
        <div className="fomo-section">
            <h1>Don't miss out on the opportunity!</h1>
            <span>Peko offers tooling that will interrupt the UI design market. Current options to design applications come with downfalls, whether it be complicated build processes, a lack of cross-platform support, or even a lack of a powerful UI. Peko solves all these problems, simplifying both the development and build steps so you can focus on perfecting your application.</span>
            <Link to="#">Learn more</Link>
        </div>
        <div className="learn-more">
            <div className="card">
                <h1>Story behind invention</h1>
                <img alt="the creator of Peko, Preston Brown" src={headshot}></img>
                <span>Learn more about this high-caliber software developed by a 14 year old.</span>
                <Link to="/story">Learn more</Link>
            </div>
            <div className="card">
                <h1>Get involved</h1>
                <img alt="a network of people interconnected" src={network}></img>
                <span>Peko has many social media accounts and contacts, offering opportunities to get involved and learn updates on this game-changing software.</span>
                <Link to="#">Learn more</Link>
            </div>
        </div>
        </>
    )
}

export default Main;