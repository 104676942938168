import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDRtOBfW5iAJn-I7qKDXS_VbxVkJnodRIM",
  authDomain: "pekoui.firebaseapp.com",
  projectId: "pekoui",
  storageBucket: "pekoui.appspot.com",
  messagingSenderId: "199847400850",
  appId: "1:199847400850:web:0f8aa3249c573729ed9d4b",
  measurementId: "G-HG018FLEW5"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database, app };