import { BrowserRouter, Routes, Route } from "react-router-dom";

import React from "react";

import Layout from './Layout';
import Main from './Pages/Main';
import Features from './Pages/Features';
import Story from './Pages/Story';
import Socials from './Pages/Socials';
import Contact from './Pages/Contact';
import './Styles/global.scss';

function App(props) {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" Component={Layout}>
                    <Route index element={<Main />} />
                    <Route path="features" element={<Features />} />
                    <Route path="story" element={<Story />} />
                    <Route path="socials" element={<Socials />} />
                    <Route path="contact" element={<Contact />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;