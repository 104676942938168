import { React } from "react";
import { Link } from "react-router-dom";
import "../Styles/Socials.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube, faInstagram, faReddit, } from "@fortawesome/free-brands-svg-icons";
import { InstagramEmbed } from 'react-social-media-embed';
import { Helmet } from "react-helmet";

export default function Socials() {
    let youtube_embed;
    let instagram_embed;
    let reddit_embed;

    if (window.innerWidth <= 480) {
        youtube_embed = (<iframe width={window.innerWidth} height={window.innerWidth*(315/560)} src="https://www.youtube.com/embed/1wtENvE79YQ?si=u-GexNbwoOyZlbYI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>);
        instagram_embed = (<InstagramEmbed className="embedded"  url="https://www.instagram.com/peko_official/?utm_source=ig_embed&amp;utm_campaign=loading" width={window.innerWidth} height={window.innerWidth*(315/560)}></InstagramEmbed>);
        reddit_embed = (<iframe title="peko subreddit" className="embedded"  id="reddit-embed" src="https://www.redditmedia.com/r/PekoUI?ref\_source=embed\&amp;ref=share\&amp;embed=true" sandbox="allow-scripts allow-same-origin allow-popups" style={{border: "none"}} width={window.innerWidth} height={window.innerWidth*(315/560)}></iframe>);
    } else {
        youtube_embed = (<iframe width={560} height={315} src="https://www.youtube.com/embed/1wtENvE79YQ?si=u-GexNbwoOyZlbYI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>);
        instagram_embed = (<InstagramEmbed className="embedded"  url="https://www.instagram.com/peko_official/?utm_source=ig_embed&amp;utm_campaign=loading" width={560} height={315}></InstagramEmbed>);
        reddit_embed = (<iframe title="peko subreddit" className="embedded"  id="reddit-embed" src="https://www.redditmedia.com/r/PekoUI?ref\_source=embed\&amp;ref=share\&amp;embed=true" sandbox="allow-scripts allow-same-origin allow-popups" style={{border: "none"}} height="315" width="560"></iframe>);
    }

    return (
        <>
            <Helmet>
                <title>Peko - Socials</title>
                <meta property="og:title" content="Peko - Socials" />
                <meta property="og:description" content="Access the socials for Peko to get updates and news on all things Peko" />
                <meta name="description" content="Access the socials for Peko to get updates and news on all things Peko" />
                <meta property="og:url" content="https://pekoui.com/socials" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className="header-bar">
                <h1>Get In The Loop</h1>
                <hr></hr>
            </div>
            <div className="socials">
                <div className="social-box">
                    {youtube_embed}
                    <div className="description">
                        <h1>Peko Youtube</h1>
                        <span className="details">The Peko youtube channel is a great source for info on Peko, from new features to the overall usage of Peko. Additionally, tutorials will be posted here to learn more about Peko.</span>
                        <div className="social-link"><FontAwesomeIcon id="youtube-icon" className="icon" icon={faYoutube} /><Link target="_blank" to="https://youtube.com/@peko.official">peko.official</Link></div>
                    </div>
                </div>
                <div className="social-box">
                    <div className="description">
                        <h1>Peko Instagram</h1>
                        <span className="details">The Peko Instagram is another good location for announcments. Additionally, shorter tutorials and help guides will be posted here</span>
                        <div className="social-link"><FontAwesomeIcon id="insta-icon" className="icon" icon={faInstagram} /><Link target="_blank" to="https://www.instagram.com/peko_official">peko_official</Link></div>
                    </div>
                    { instagram_embed }
                </div>
                <div className="social-box">
                    {reddit_embed}
                    <div className="description">
                        <h1>Peko Reddit</h1>
                        <span className="details">The PekoUI subreddit is amazing for announcements, discussions, and questions about Peko.</span>
                        <div className="social-link"><FontAwesomeIcon id="reddit-icon" className="icon" icon={faReddit} /><Link target="_blank" to="https://www.reddit.com/r/PekoUI/">r/PekoUI</Link></div>
                    </div>
                </div>
            </div>
        </>
    )
}