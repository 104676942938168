import { React, useState } from "react";
import "../Styles/Contact.scss"
import { ref, set } from "firebase/database";
import { database, app } from "../Firebase"
import { faUser, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Helmet } from "react-helmet";

export default function Socials() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [emailGood, setEmailGood] = useState(false);
    const [popup, setPopup] = useState(false);
    
    const getTimeId = () => {
        let time = new Date().getTime().toString();
        return time + Math.floor(Math.random() * 8999 + 1000);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        set(ref(database, "inquiries/" + getTimeId()), {
            name: name,
            email: email,
            message: message
        });

        httpsCallable(getFunctions(app), `sendMail?dest=${email}&name=${name}`)().then((result) => {
            console.log(result.data);
        }).catch((error) => {
            console.log(error)
        });
        
        setName("");
        setEmail("");
        setMessage("");
        setPopup(true);
    }

    const verifyEmail = (event) => {
        setEmail(event.target.value);
        // eslint-disable-next-line no-control-regex
        setEmailGood(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(event.target.value))
    }

    return (
        <>
            <Helmet>
                <title>Peko - Contact Us</title>
                <meta property="og:title" content="Peko" />
                <meta property="og:description" content="Inquire about Peko here" />
                <meta name="description" content="Inquire about Peko here" />
                <meta property="og:url" content="https://pekoui.com/contact" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className="header-bar">
                <h1>Get Connected</h1>
                <hr></hr>
                <form className="contact-form" onSubmit={handleSubmit}>
                    {
                        (popup) ?  <h1 className="popup">Inquiry sent</h1> : <></>
                    }
                    <h1>Contact Us!</h1>
                    <label>FULL NAME</label>
                    <div className="input-highlight"><span><FontAwesomeIcon className="icon" icon={faUser}></FontAwesomeIcon></span><input type="text" value={name} onChange={(e) => { setName(e.target.value) }}></input></div>
                    <label>EMAIL</label>
                    <div className="input-highlight"><span><FontAwesomeIcon className="icon" icon={faEnvelope}></FontAwesomeIcon></span><input className={emailGood ? "" : "bad-input"} type="text" value={email} onChange={(e) => { verifyEmail(e) }}></input></div>
                    <label>MESSAGE</label>
                    <div className="input-highlight"><textarea className="message" value={message} onChange={(e) => { setMessage(e.target.value) }}></textarea></div>
                    <input disabled={!emailGood || name === "" || message === ""} type="submit" value="Send Message"></input>
                </form>
            </div>
        </>
    )
}