import { Outlet } from "react-router-dom";
import Navbar from './Components/Navbar';
import logo from "./Images/logo.png"

import "./Styles/footer.scss";
import React from 'react';
import { Helmet } from "react-helmet";

class Layout extends React.Component {
    render() {
        return (
            <>
                <Navbar></Navbar>
                <div className="pagecontent">
                    <Outlet />
                    <div className="footer-margin"></div>
                </div>
                <div className="footer">
                    <img alt="Peko logo" src={logo}></img>
                    <span>&copy; 2020 Preston Brown</span>
                </div>
            </>
        )
    }
}

export default Layout;